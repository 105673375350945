import SecurePortfolio from 'images/investment-portfolio-tax/secure-portfolio-sharing.inline.svg';
import DividendTracking from 'images/investment-portfolio-tax/dividend-income-tracking.inline.svg';
import InvestmentReport from 'images/investment-portfolio-tax/investment-tax-reports.inline.svg';

export const portfolioTaxCards = [
  {
    cardIdentifier: 'track-1',
    title: 'Investment tax reports',
    text: 'Easily calculate the tax implications of investing across your entire portfolio with Sharesight’s investor reports.',
    image: <InvestmentReport />,
  },
  {
    cardIdentifier: 'track-2',
    title: 'Dividend income tracking',
    text: 'Report income from dividend, distribution and interest payments for all your investments.',
    image: <DividendTracking />,
  },
  {
    cardIdentifier: 'track-3',
    title: 'Secure portfolio sharing',
    text: 'Share secure portfolio access and tax reports with your accountant to stay on top of your investment portfolio.',
    image: <SecurePortfolio />,
  },
];
