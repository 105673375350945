import { useGatsbyImage } from 'hooks/useGatsbyImage';
import { useContentfulLocale } from 'hooks/useContentfulLocale';

import { Container, Section, SectionImage } from 'components/Container';

import type { FC } from 'react';

export const TaxDeductibleBlock: FC = () => {
  const {
    priceIncludesTax,
    translations: { endOfFiscalYear },
  } = useContentfulLocale();

  const image = useGatsbyImage({
    name: 'tax-deductible',
    alt: 'Your Sharesight subscription may be tax deductible.',
  });

  if (!priceIncludesTax || !endOfFiscalYear) return null;

  return (
    <Container id="tax-deductible">
      <Section>
        <h2>Your Sharesight subscription is tax deductible*</h2>
        <p>
          Pay before {endOfFiscalYear} and claim your subscription expenses in your tax return.
          <em>*Check with your accountant</em>
        </p>
      </Section>

      <SectionImage image={image} boxShadow={false} />
    </Container>
  );
};
