import { GatsbyImage } from 'gatsby-plugin-image';

import { StyledImage } from 'hooks/useGatsbyImage/StyledImage.styled';

import { isGatsbyImage } from 'helpers/isGatsbyImage';
import { isContentfulSVGImage } from 'helpers/isContentfulSVGImage';

import type { ContentBlockImage } from './types';

export const resolveContentBlockImage = (img: ContentBlockImage) => {
  if (isGatsbyImage(img)) {
    return <GatsbyImage image={img.gatsbyImageData} alt={img.title} />;
  }
  if (isContentfulSVGImage(img)) {
    return <StyledImage src={img.file.url} alt={img.title} />;
  }
  return img;
};
