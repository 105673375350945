import { isRichText } from 'helpers/isRichText';

import { Container, Section, SectionImage } from 'components/Container';
import { RichText } from 'components/RichText';

import { resolveContentBlockImage } from './resolveContentBlockImage';

import type { FC } from 'react';
import type { ContentBlockProps } from './types';

export const ContentBlock: FC<ContentBlockProps> = ({
  header,
  isHero,
  textContent,
  button,
  image,
  manageLayout,
  center,
  padding = 10,
}) => (
  <Container padding={padding} scaleMobileGap manageLayout={manageLayout}>
    <Section center={center}>
      {isHero ? <h1>{header}</h1> : <h2>{header}</h2>}

      {isRichText(textContent) ? <RichText richText={textContent} /> : textContent}

      {(isRichText(textContent) || button) && <br />}

      {button}
    </Section>

    <SectionImage image={resolveContentBlockImage(image)} boxShadow={false} />
  </Container>
);
